import { useState, useEffect } from "react";
import PageData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.scss";

{/* 
import { Navigation } from "./components/Navigation";
import { Header } from "./components/Header";
import { PriceList } from "./components/PriceList";
import { About } from "./components/About";
import { SupportAreas } from "./components/SupportAreas";
import { Contact } from "./components/Contact";
import { FreeMaterials } from "./components/FreeMaterials";
import { Recommendation } from "./components/Recommendation";
import { Intro } from "./components/Intro";
*/}

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 600,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(PageData);
  }, []);

  return (
    <div>
      <div className="text-center">
        <img src="img/landing.png" className="img-fluid w-75" alt="Aleksandra Kawa, strona w przebudowie" />
      </div>
      {/*
      <Navigation />
      <Header data={landingPageData.Header} />
      <Intro data={landingPageData.Intro} />
      <About data={landingPageData.About} />
      <SupportAreas data={landingPageData.SupportAreas} />
      <PriceList data={landingPageData.Price} />
      <Recommendation data={landingPageData.Recommendations} />
      <FreeMaterials data={landingPageData.Price} />
      <Contact data={landingPageData.Contact} />
      */}
    </div>
  );
};

export default App;
